import React, { useEffect, useState } from "react";
import { NavList } from "../NavList";
import { AiOutlineClose, AiOutlineMenu } from "react-icons/ai";
import { Link } from "react-router-dom";
import { useWidth } from "../../hooks/UseWidth";
import { HeaderLogo, HeaderMobileLogo } from "../../customIcons";
import style from "./Navbar.module.css";

export const Navbar = () => {
  const [nav, setNav] = useState(false);
  const screenWidth = useWidth();

  useEffect(() => {
    const body = document.querySelector("body");
    if (nav) {
      body.classList.add("no-scroll");
    } else {
      body.classList.remove("no-scroll");
    }
  }, [nav]);

  return (
    <header className={style.header} id='header'>
      <div className="container">
        <div className={style.box}>
          <Link to={"/"}>
            {screenWidth > 800 ? <HeaderLogo /> : <HeaderMobileLogo />}
          </Link>
          <NavList nav={nav} setNav={setNav} />
          <div
            style={{ zIndex: "999" }}
            onClick={() => setNav(!nav)}
            className={style.mobile_btn}
          >
            {nav ? <AiOutlineClose size={25} /> : <AiOutlineMenu size={25} />}
          </div>
        </div>
      </div>
    </header>
  );
};
