import project1 from "../../assets/projects/1/main.jpg";
import project1_child1 from "../../assets/projects/1/1.jpg";
import project1_child2 from "../../assets/projects/1/2.jpg";
import project1_child3 from "../../assets/projects/1/3.jpg";
import project1_child4 from "../../assets/projects/1/4.jpg";
import project2 from "../../assets/projects/2/main.jpg";
import project2_child1 from "../../assets/projects/2/1.jpg";
import project2_child2 from "../../assets/projects/2/2.jpg";
import project2_child3 from "../../assets/projects/2/3.jpg";
import project2_child4 from "../../assets/projects/2/4.jpg";
import project3 from "../../assets/projects/3/main.jpg";
import project3_child1 from "../../assets/projects/3/1.jpg";
import project3_child2 from "../../assets/projects/3/2.jpg";
import project3_child3 from "../../assets/projects/3/3.jpg";
import project3_child4 from "../../assets/projects/3/4.jpg";
import project4 from "../../assets/projects/4/main.jpg";
import project4_child1 from "../../assets/projects/4/1.jpg";
import project4_child2 from "../../assets/projects/4/2.jpg";
import project4_child3 from "../../assets/projects/4/3.jpg";
import project4_child4 from "../../assets/projects/4/4.jpg";
// Denver January 2023
import DenverJanuary2023Main from "../../assets/projects/denver_january_2023/main.jpg";
import DenverJanuary2023Imag1 from "../../assets/projects/denver_january_2023/1.jpg";
import DenverJanuary2023Imag2 from "../../assets/projects/denver_january_2023/2.jpg";
import DenverJanuary2023Imag3 from "../../assets/projects/denver_january_2023/3.jpg";
import DenverJanuary2023Imag4 from "../../assets/projects/denver_january_2023/4.jpg";
import DenverJanuary2023Imag5 from "../../assets/projects/denver_january_2023/5.jpg";
import DenverJanuary2023Imag6 from "../../assets/projects/denver_january_2023/6.jpg";
import DenverJanuary2023Imag7 from "../../assets/projects/denver_january_2023/7.jpg";
import DenverJanuary2023Imag8 from "../../assets/projects/denver_january_2023/8.jpg";
// Denver Novemner 2021
import DenverNovember2023 from "../../assets/projects/denver_november_2021/main.jpg";
import DenverNovember2023Image1 from "../../assets/projects/denver_november_2021/1.jpg";
import DenverNovember2023Image2 from "../../assets/projects/denver_november_2021/2.jpg";
import DenverNovember2023Image3 from "../../assets/projects/denver_november_2021/3.jpg";
import DenverNovember2023Image4 from "../../assets/projects/denver_november_2021/4.jpg";
import DenverNovember2023Image5 from "../../assets/projects/denver_november_2021/5.jpg";
import DenverNovember2023Image6 from "../../assets/projects/denver_november_2021/6.jpg";
// Genesse December 2022
import GenesseDecember2022 from "../../assets/projects/genesse_december_2022/main.jpg";
import GenesseDecember2022Image1 from "../../assets/projects/genesse_december_2022/1.jpg";
import GenesseDecember2022Image2 from "../../assets/projects/genesse_december_2022/2.jpg";
import GenesseDecember2022Image3 from "../../assets/projects/genesse_december_2022/3.jpg";
import GenesseDecember2022Image4 from "../../assets/projects/genesse_december_2022/4.jpg";
import GenesseDecember2022Image5 from "../../assets/projects/genesse_december_2022/5.jpg";
import GenesseDecember2022Image6 from "../../assets/projects/genesse_december_2022/6.jpg";
// Genesse June 2023
import GenesseJune2023 from "../../assets/projects/genesse_june_2023/main.jpg";
import GenesseJune2023Image1 from "../../assets/projects/genesse_june_2023/1.jpg";
import GenesseJune2023Image2 from "../../assets/projects/genesse_june_2023/2.jpg";
import GenesseJune2023Image3 from "../../assets/projects/genesse_june_2023/3.jpg";
import GenesseJune2023Image4 from "../../assets/projects/genesse_june_2023/4.jpg";
import GenesseJune2023Image5 from "../../assets/projects/genesse_june_2023/5.jpg";
import GenesseJune2023Image6 from "../../assets/projects/genesse_june_2023/6.jpg";
import GenesseJune2023Image7 from "../../assets/projects/genesse_june_2023/7.jpg";
import GenesseJune2023Image8 from "../../assets/projects/genesse_june_2023/8.jpg";
// Evergreen_september_2024
import EvergreenSeptember2024 from "../../assets/projects/evergreen_september_2024/main.jpg";
import EvergreenSeptember2024Image1 from "../../assets/projects/evergreen_september_2024/1.jpg";
import EvergreenSeptember2024Image2 from "../../assets/projects/evergreen_september_2024/2.jpg";
import EvergreenSeptember2024Image3 from "../../assets/projects/evergreen_september_2024/3.jpg";
import EvergreenSeptember2024Image4 from "../../assets/projects/evergreen_september_2024/4.jpg";
import EvergreenSeptember2024Image5 from "../../assets/projects/evergreen_september_2024/5.jpg";
import EvergreenSeptember2024Image6 from "../../assets/projects/evergreen_september_2024/6.jpg";
import EvergreenSeptember2024Image7 from "../../assets/projects/evergreen_september_2024/7.jpg";
import EvergreenSeptember2024Image8 from "../../assets/projects/evergreen_september_2024/8.jpg";
import EvergreenSeptember2024Image9 from "../../assets/projects/evergreen_september_2024/9.jpg";
import EvergreenSeptember2024Image10 from "../../assets/projects/evergreen_september_2024/10.jpg";
import EvergreenSeptember2024Image11 from "../../assets/projects/evergreen_september_2024/11.jpg";
import EvergreenSeptember2024Image12 from "../../assets/projects/evergreen_september_2024/12.jpg";
import EvergreenSeptember2024Image13 from "../../assets/projects/evergreen_september_2024/13.jpg";
import EvergreenSeptember2024Image14 from "../../assets/projects/evergreen_september_2024/14.jpg";
import EvergreenSeptember2024Image15 from "../../assets/projects/evergreen_september_2024/15.jpg";

export const PORTFOLIO_LIST = [
  {
    id: 1,
    img: project1,
    alt: "Winter Park",
    title: "Winter Park",
    text: "Feb 2023",
    video: "project1.mp4",
    collection: [
      {
        id: 1,
        img: project1_child1,
        alt: "Winter Park",
        title: "Winter Park",
        text: "Feb 2023",
      },
      {
        id: 2,
        img: project1_child2,
        alt: "Winter Park",
        title: "Winter Park",
        text: "Feb 2023",
      },
      {
        id: 3,
        img: project1_child3,
        alt: "Winter Park",
        title: "Winter Park",
        text: "Feb 2023",
      },
      {
        id: 4,
        img: project1_child4,
        alt: "Winter Park",
        title: "Winter Park",
        text: "Feb 2023",
      },
    ],
  },
  {
    id: 2,
    img: project2,
    alt: "Aurora",
    title: "Aurora",
    text: "Oct 2020",
    // video: "project2.mp4",
    collection: [
      {
        id: 1,
        img: project2_child1,
        alt: "Aurora",
        title: "Aurora",
        text: "Oct 2020",
      },
      {
        id: 2,
        img: project2_child2,
        alt: "Aurora",
        title: "Aurora",
        text: "Oct 2020",
      },
      {
        id: 3,
        img: project2_child3,
        alt: "Aurora",
        title: "Aurora",
        text: "Oct 2020",
      },
      {
        id: 4,
        img: project2_child4,
        alt: "Aurora",
        title: "Aurora",
        text: "Oct 2020",
      },
    ],
  },
  {
    id: 3,
    img: project3,
    alt: "Denver",
    title: "Denver",
    text: "Jun 2021",
    video: "project3.mp4",
    collection: [
      {
        id: 1,
        img: project3_child1,
        alt: "Denver",
        title: "Denver",
        text: "Jun 2021",
      },
      {
        id: 2,
        img: project3_child2,
        alt: "Denver",
        title: "Denver",
        text: "Jun 2021",
      },
      {
        id: 3,
        img: project3_child3,
        alt: "Denver",
        title: "Denver",
        text: "Jun 2021",
      },
      {
        id: 4,
        img: project3_child4,
        alt: "Denver",
        title: "Denver",
        text: "Jun 2021",
      },
    ],
  },
  {
    id: 4,
    img: project4,
    alt: "Genesee",
    title: "Genesee",
    text: "Aug 2021",
    collection: [
      {
        id: 1,
        img: project4_child1,
        alt: "Genesee",
        title: "Genesee",
        text: "Aug 2021",
      },
      {
        id: 2,
        img: project4_child2,
        alt: "Genesee",
        title: "Genesee",
        text: "Aug 2021",
      },
      {
        id: 3,
        img: project4_child3,
        alt: "Genesee",
        title: "Genesee",
        text: "Aug 2021",
      },
      {
        id: 4,
        img: project4_child4,
        alt: "Genesee",
        title: "Genesee",
        text: "Aug 2021",
      },
    ],
  },
  {
    id: 5,
    img: DenverJanuary2023Main,
    alt: "Denver",
    title: "Denver",
    text: "Jan 2023",
    collection: [
      {
        id: 1,
        img: DenverJanuary2023Imag1,
        alt: "Denver",
        title: "Denver",
        text: "Jan 2023",
      },
      {
        id: 2,
        img: DenverJanuary2023Imag2,
        alt: "Denver",
        title: "Denver",
        text: "Jan 2023",
      },
      {
        id: 3,
        img: DenverJanuary2023Imag3,
        alt: "Denver",
        title: "Denver",
        text: "Jan 2023",
      },
      {
        id: 4,
        img: DenverJanuary2023Imag4,
        alt: "Denver",
        title: "Denver",
        text: "Jan 2023",
      },
      {
        id: 5,
        img: DenverJanuary2023Imag5,
        alt: "Denver",
        title: "Denver",
        text: "Jan 2023",
      },
      {
        id: 6,
        img: DenverJanuary2023Imag6,
        alt: "Denver",
        title: "Denver",
        text: "Jan 2023",
      },
      {
        id: 7,
        img: DenverJanuary2023Imag7,
        alt: "Denver",
        title: "Denver",
        text: "Jan 2023",
      },
      {
        id: 8,
        img: DenverJanuary2023Imag8,
        alt: "Denver",
        title: "Denver",
        text: "Jan 2023",
      },
    ],
  },
  {
    id: 6,
    img: DenverNovember2023,
    alt: "Denver",
    title: "Denver",
    text: "Nov 2021",
    collection: [
      {
        id: 1,
        img: DenverNovember2023Image1,
        alt: "Denver",
        title: "Denver",
        text: "Nov 2021",
      },
      {
        id: 2,
        img: DenverNovember2023Image2,
        alt: "Denver",
        title: "Denver",
        text: "Nov 2021",
      },
      {
        id: 3,
        img: DenverNovember2023Image3,
        alt: "Denver",
        title: "Denver",
        text: "Nov 2021",
      },
      {
        id: 4,
        img: DenverNovember2023Image4,
        alt: "Denver",
        title: "Denver",
        text: "Nov 2021",
      },
      {
        id: 5,
        img: DenverNovember2023Image5,
        alt: "Denver",
        title: "Denver",
        text: "Nov 2021",
      },
      {
        id: 6,
        img: DenverNovember2023Image6,
        alt: "Denver",
        title: "Denver",
        text: "Nov 2021",
      },
    ],
  },
  {
    id: 7,
    img: GenesseDecember2022,
    alt: "Genesse",
    title: "Genesse",
    text: "Dec 2022",
    collection: [
      {
        id: 1,
        img: GenesseDecember2022Image1,
        alt: "Genesse",
        title: "Genesse",
        text: "Dec 2022",
      },
      {
        id: 2,
        img: GenesseDecember2022Image2,
        alt: "Genesse",
        title: "Genesse",
        text: "Dec 2022",
      },
      {
        id: 3,
        img: GenesseDecember2022Image3,
        alt: "Genesse",
        title: "Genesse",
        text: "Dec 2022",
      },
      {
        id: 4,
        img: GenesseDecember2022Image4,
        alt: "Genesse",
        title: "Genesse",
        text: "Dec 2022",
      },
      {
        id: 5,
        img: GenesseDecember2022Image5,
        alt: "Genesse",
        title: "Genesse",
        text: "Dec 2022",
      },
      {
        id: 6,
        img: GenesseDecember2022Image6,
        alt: "Genesse",
        title: "Genesse",
        text: "Dec 2022",
      },
    ],
  },
  {
    id: 8,
    img: GenesseJune2023,
    alt: "Genesse",
    title: "Genesse",
    text: "Jun 2023",
    collection: [
      {
        id: 1,
        img: GenesseJune2023Image1,
        alt: "Genesse",
        title: "Genesse",
        text: "Jun 2023",
      },
      {
        id: 2,
        img: GenesseJune2023Image2,
        alt: "Genesse",
        title: "Genesse",
        text: "Jun 2023",
      },
      {
        id: 3,
        img: GenesseJune2023Image3,
        alt: "Genesse",
        title: "Genesse",
        text: "Jun 2023",
      },
      {
        id: 4,
        img: GenesseJune2023Image4,
        alt: "Genesse",
        title: "Genesse",
        text: "Jun 2023",
      },
      {
        id: 5,
        img: GenesseJune2023Image5,
        alt: "Genesse",
        title: "Genesse",
        text: "Jun 2023",
      },
      {
        id: 6,
        img: GenesseJune2023Image6,
        alt: "Genesse",
        title: "Genesse",
        text: "Jun 2023",
      },
      {
        id: 7,
        img: GenesseJune2023Image7,
        alt: "Genesse",
        title: "Genesse",
        text: "Jun 2023",
      },
      {
        id: 8,
        img: GenesseJune2023Image8,
        alt: "Genesse",
        title: "Genesse",
        text: "Jun 2023",
      },
    ],
  },
  {
    id: 9,
    img: EvergreenSeptember2024,
    alt: "Evergreen",
    title: "Evergreen",
    text: "Sep 2024",
    collection: [
      {
        id: 1,
        img: EvergreenSeptember2024Image1,
        alt: "Evergreen",
        title: "Evergreen",
        text: "Sep 2024",
      },
      {
        id: 2,
        img: EvergreenSeptember2024Image2,
        alt: "Evergreen",
        title: "Evergreen",
        text: "Sep 2024",
      },
      {
        id: 3,
        img: EvergreenSeptember2024Image3,
        alt: "Evergreen",
        title: "Evergreen",
        text: "Sep 2024",
      },
      {
        id: 4,
        img: EvergreenSeptember2024Image4,
        alt: "Evergreen",
        title: "Evergreen",
        text: "Sep 2024",
      },
      {
        id: 5,
        img: EvergreenSeptember2024Image5,
        alt: "Evergreen",
        title: "Evergreen",
        text: "Sep 2024",
      },
      {
        id: 6,
        img: EvergreenSeptember2024Image6,
        alt: "Evergreen",
        title: "Evergreen",
        text: "Sep 2024",
      },
      {
        id: 7,
        img: EvergreenSeptember2024Image7,
        alt: "Evergreen",
        title: "Evergreen",
        text: "Sep 2024",
      },
      {
        id: 8,
        img: EvergreenSeptember2024Image8,
        alt: "Evergreen",
        title: "Evergreen",
        text: "Sep 2024",
      },
      {
        id: 9,
        img: EvergreenSeptember2024Image9,
        alt: "Evergreen",
        title: "Evergreen",
        text: "Sep 2024",
      },
      {
        id: 10,
        img: EvergreenSeptember2024Image10,
        alt: "Evergreen",
        title: "Evergreen",
        text: "Sep 2024",
      },
      {
        id: 11,
        img: EvergreenSeptember2024Image11,
        alt: "Evergreen",
        title: "Evergreen",
        text: "Sep 2024",
      },
      {
        id: 12,
        img: EvergreenSeptember2024Image12,
        alt: "Evergreen",
        title: "Evergreen",
        text: "Sep 2024",
      },
      {
        id: 13,
      img: EvergreenSeptember2024Image13,
        alt: "Evergreen",
        title: "Evergreen",
        text: "Sep 2024",
      },
      {
        id: 14,
        img: EvergreenSeptember2024Image14,
        alt: "Evergreen",
        title: "Evergreen",
        text: "Sep 2024",
      },
      {
        id: 15,
        img: EvergreenSeptember2024Image15,
        alt: "Evergreen",
        title: "Evergreen",
        text: "Sep 2024",
      },
    ],
  },
];
