import React from "react";
import { Title } from "../Title";
import style from "./Portfolio.module.css";
import { PORTFOLIO_LIST } from "../../common";
import { Image } from "../../ui";

export const Portfolio = () => {
  return (
    <div className={style.portfolioContainer}>
      <div className={"container"}>
        <Title
          mb={window.innerWidth > 800 ? "40px" : "20px"}
          title={"Our projects"}
        />
        <div className={style.portfolioList}>
          {PORTFOLIO_LIST.sort((a, b) => {
            const dateA = new Date(a.text);
            const dateB = new Date(b.text);
            return dateB - dateA;
          }).map(({ title, text, alt, img, id, video }) => (
            <Image
              key={id}
              title={title}
              text={text}
              alt={alt}
              img={img}
              id={id}
            />
          ))}
        </div>
      </div>
    </div>
  );
};
