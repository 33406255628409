import React from "react";
import style from "./OurProjects.module.css";
import { Title } from "../Title";
import { Button, Image } from "../../ui";
import { RouteNames } from "../../router";
import image from "../../assets/projects/1/1.jpg";
import image1 from "../../assets/projects/4/main.jpg";
import image2 from "../../assets/projects/3/4.jpg";
import { useWidth } from "../../hooks/UseWidth";
import { PORTFOLIO_LIST } from "../../common";

export const OurProjects = () => {
  const windowWidth = useWidth();
  return (
    <div className={style.ourProjectContainer}>
      <div className="container">
        <Title
          title={"Our projects"}
          mb={windowWidth > 800 ? "40px" : "20px"}
        />
        <div className={style.ourProjectList}>
          {PORTFOLIO_LIST.sort((a, b) => {
            const dateA = new Date(a.text);
            const dateB = new Date(b.text);
            return dateB - dateA;
          })
            .slice(0, 3)
            .map(({ title, text, alt, img, id, video }) => (
              <Image
                key={id}
                title={title}
                text={text}
                alt={alt}
                img={img}
                id={id}
                width={408}
                height={306}
              />
            ))}
        </div>
        <Button text={"See all"} path={RouteNames.PORTFOLIO} />
      </div>
    </div>
  );
};
